<template>
  <b-modal
    id="profile-picture-modal"
    hide-header
    hide-footer
    centered
    size="md"
    @hidden="cleanModal"
  >
    <div class="modal-header d-flex flex-row justify-content-between">
      <p class="modal-title">
        {{ personId ? 'Editar ' : 'Adicionar nova ' }}
        foto de perfil
      </p>
      <Close class="icon" @click="cleanModal() && closeModal()" />
    </div>
    <div class="modal-body">
      <div class="file-atach md-content" v-show="!openCamera">
        <div
          class="file-container"
          :class="{ 'without-file': !file.name }"
          @dragover.prevent
          @drop.prevent
        >
          <div @drop="dragFile" class="drag-area">
            <div v-if="!file.name">
              <p>Arraste os arquivos aqui</p>
              <div class="separator">
                <HorizontalLine /> ou <HorizontalLine />
              </div>
              <label for="add-file-input" class="add-btn">
                Anexar arquivos
              </label>
              <input
                id="add-file-input"
                type="file"
                accept=".jpg, .png"
                @change="addFile"
              />
            </div>
            <div class="file" v-else>
              <div>
                <Files />
                {{ file.name }}
              </div>
              <Close class="close" @click="removeFile" />
            </div>
          </div>
        </div>
      </div>
      <Camera
        v-show="openCamera"
        ref="camera"
        :openCamera="openCamera"
        @changeFile="changeFile"
      />
      <div class="modal-footer">
        <b-button
          class="btn-modal btn-picture mr-auto"
          @click="changeOpenCamera(true)"
        >
          <CameraIcon class="icon-camera" />Tirar foto
        </b-button>
        <b-button
          v-if="openCamera"
          variant="outline-danger"
          class="btn-modal ml-auto"
          @click="closeCamera"
        >
          Voltar
        </b-button>
        <b-button variant="primary" class="btn-modal" @click="uploadFile">
          Salvar foto
        </b-button>
      </div>
    </div>

    <CropperImage
      :selectedFile="file"
      @croped-image="cropedImage"
      @update-image="updateImage"
    />
  </b-modal>
</template>

<script>
export default {
  name: 'ProfilePictureModal',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    HorizontalLine: () => import('@/assets/icons/line.svg'),
    Files: () => import('@/assets/icons/files.svg'),
    CameraIcon: () => import('@/assets/icons/camera.svg'),
    Camera: () => import('@/components/General/Camera'),
    CropperImage: () => import('@/components/General/CropperImage')
  },
  props: {
    personId: String,
    fileProps: [Object, File]
  },
  data: () => ({
    file: {},
    openCamera: false,
    maxSize: 440
  }),
  methods: {
    changeFile(file) {
      this.file = file
    },
    async changeOpenCamera(value) {
      this.file = {}
      if (this.openCamera) {
        this.$refs.camera.takePhoto()
        this.openCamera = value
        return
      }
      this.$refs.camera.toggleCamera()
      this.openCamera = value
    },
    closeCamera() {
      this.openCamera = false
      this.file = {}
      this.$refs.camera.toggleCamera()
    },
    takePhoto() {
      this.$refs.camera.takePhoto()
    },
    async cleanModal() {
      if (this.openCamera) {
        await this.$refs.camera.clearCamera()
      }
      this.openCamera = false
      this.removeFile()
    },
    closeModal() {
      this.$bvModal.hide('profile-picture-modal')
    },
    removeFile() {
      this.file = {}
    },
    addFile(e) {
      if ((e.target.files[0].size / 1024) > this.maxSize) {
        this.$toast.error(`O arquivo tem ${e.target.files[0].size} KB, que é maior que 440 KB!`)
        return
      }

      this.file = e.target.files[0]
    },
    dragFile(e) {
      const fileType = e.dataTransfer.files[0].type
      if (['image/jpg', 'image/png', 'image/jpeg', 'tiff'].includes(fileType)) {
        this.file = e.dataTransfer.files[0]
      }
      else return this.$toast.error('Formato de arquivo não suportado. Apenas os formatos .JPG e .PNG são permitido!')
    },
    uploadFile() {
      if (!this.file.name) {
        return this.$toast.warning('Selecione um arquivo ou tire uma foto')
      }
      this.$bvModal.show('crop-modal')
      this.cleanModal()
    },
    cropedImage(file) {
      this.$emit('change-croped-image', file)
      this.cleanModal()
      this.closeModal()
    },
    updateImage(file) {
      this.$emit('update-image', file)
    }
  },
  watch: {
    fileProps: {
      handler(value) {
        if (!value?.name) return
        this.file = value
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#profile-picture-modal {
  .modal-content {
    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;
      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(----type-active);
      }
      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
    border-radius: 8px;
    .modal-body {
      padding: 0 !important;
      margin: 0 !important;
      .file-atach {
        .file-container {
          height: 100px;
          background: transparent;
          border-top: 1px solid var(--neutral-200);
          border-bottom: 1px solid var(--neutral-200);
          &.without-file {
            height: 160px;
            border: 1px dashed var(--blue-500);
            box-sizing: border-box;
            border-radius: 8px;
            background: var(--neutral-100);
          }
          .drag-area {
            width: 100%;
            height: 100%;
            padding: 20px;
            font-weight: bold;
            font-size: 16px;
            line-height: 130%;
            color: var(--type-active);
            text-align: center;
            .separator {
              margin: 12px 0px;
            }
            .add-btn {
              background: var(--blue-700);
              border-radius: 8px;
              color: var(--neutral-000);
              padding: 8px 15px;
              cursor: pointer;
              font-family: Nunito Sans;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
              letter-spacing: 0em;
              text-align: left;
            }
            #add-file-input {
              display: none;
            }
            .file {
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: space-between;
              align-content: center;
              svg {
                width: 42px !important;
                height: 42px !important;
              }
              .close {
                width: 20px !important;
                height: 20px !important;
              }
            }
          }
        }
      }
      .btn-modal {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        margin-top: 20px;
        width: 122px;
        border-radius: 8px;
        height: 38px;
      }
      .btn-picture {
        background-color: var(--orange);
        width: 144px !important;
      }
      .icon-camera {
        margin-right: 5px;
        fill: var(--neutral-000);
        width: 22px;
        height: 18px;
      }
    }
    .md-content {
      padding: 30px 20px 10px 20px;
    }
  }
}
</style>
